import { useState, useEffect } from "react";
import { useEthers, Moonbeam } from "@usedapp/core";

import Actions from "./Actions";
import AvailableTribal from "../tribal/AvailableTribal";
import ReserveBalance from "../tribal/ReserveBalance";
import TribalBalance from "../tribal/TribalBalance";
import SwitchToAuthorizedNetwork from "../tribal/SwitchToAuthorizedNetwork";
import TokenInputs from "./TokenInputs";

function SwapKLIMAforStory() {
  const { chainId } = useEthers();

  return (
    <div className="flex justify-center py-4">
      <div className="flex flex-col rounded shadow-lg bg-[#FFF6E8] border-y border-primary-main">
        <div className="pb-2 pt-6 px-4">
          {chainId && chainId !== Moonbeam.chainId && (
            <SwitchToAuthorizedNetwork />
          )}
          {chainId && <AvailableTribal />}
          {chainId && <ReserveBalance />}
          {chainId && <TribalBalance />}
        </div>

        <TokenInputs />

        {chainId && <Actions />}
      </div>
    </div>
  );
}

export default SwapKLIMAforStory;
