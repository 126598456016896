import { useTokenBalance, useEthers } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";

import KLIMASpan from "../span/KLIMASpan";
import GLMRSpan from "../span/GLMRSpan";

import { RESERVES } from "../../containers/Addresses";
import Balance from "../Balance";


function ReserveBalance() {
  const { account, chainId } = useEthers();

  const reserveAddress = RESERVES[chainId] ?? "";

  const reserveBalance = useTokenBalance(reserveAddress, account);

  const number = reserveBalance
    ? Number(formatEther(reserveBalance)).toFixed(2)
    : undefined;

  return (
    <Balance label="Has for Exchange:" balance={number}>
      { chainId && chainId !== 1284 && <KLIMASpan /> }
      { chainId && chainId === 1284 && <GLMRSpan /> }
    </Balance>
  );
}

export default ReserveBalance;
