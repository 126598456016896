import { utils, Contract } from "ethers";
import { useEthers, useCall } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";

import Balance from "../Balance";
import TribalTokenSpan from "../span/TribalTokenSpan";

import deployments from "../../tribal/deployments.json";
import TribalDistributorJSON from "../../tribal/contracts/TribalDistributor.sol/TribalDistributor.json";

const distributorInterface = new utils.Interface(TribalDistributorJSON.abi);

function useGetAvailableTribal(distributorAddress) {
  const { value, error } =
    useCall(
      distributorAddress && {
        contract: new Contract(distributorAddress, distributorInterface),
        method: "getAvailableTribal",
        args: [],
      }
    ) ?? {};

  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

function AvailableTribal(props) {
  const { chainId } = useEthers();

  const aDeployment = deployments[chainId] ?? "";
  const distributorAddress =
    aDeployment && aDeployment.addresses.tribalDistributor;

  const available = useGetAvailableTribal(distributorAddress);

  const number = available
    ? Number(formatEther(available)).toFixed(2)
    : undefined;

  return (
    <Balance label="Available $Tribal:" balance={number}>
      <TribalTokenSpan />
    </Balance>
  );
}

export default AvailableTribal;
