import TribalCreatesSection from "../components/sections/TribalCreatesSection"
import TribalIsChainedSection from "../components/sections/TribalIsChainedSection"
import TribalInfoSection from "../components/sections/TribalInfoSection"

function WhatIsTribal() {
  return (
    <div>
      <TribalCreatesSection />
      <TribalIsChainedSection />
      <TribalInfoSection />
    </div>
  );
}

export default WhatIsTribal;
