import { useEthers } from "@usedapp/core";

import KLIMASpan from "../span/KLIMASpan";
import GLMRSpan from "../span/GLMRSpan";

function AcquireKLIMA() {
  const { chainId } = useEthers();

  return (
    <div class="mx-8 my-4 rounded shadow-lg bg-white">
      <div class="px-4 py-2">
        <div class="font-bold text-xl">
          1. Acquire {' '}
          { chainId && chainId !== 1284 && <KLIMASpan /> }
          { chainId && chainId === 1284 && <GLMRSpan /> }
          .
        </div>
        {chainId && chainId !== 1284 && (
          <div>
            <span class="text-gray-700 text-base">
              Acquire KLIMA tokens at our partner site:{" "}
            </span>
            <a class="link link-primary" href="https://www.klimadao.finance/">
              KLIMA Dao
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default AcquireKLIMA;
