import TribalSlash from "./Home/TribalSlash"

function TribalSection(props) {


  return (
    <div className={ props.bgColor + " py-20 min-h-32 sm:py-52 sm:text-center" } >
      <div className={ "max-w-7xl mx-auto px-4 sm:px-6" } >

        <h1 className= { (props.textAccent ?? "text-secondary-main") + " py-4 text-4xl sm:text-6xl font-semibold " }>
            { props.title }
        </h1>

        <h2 className={ props.textColor + " font-light py-4 text-base sm:text-lg" }>
            <span>{ props.header }</span>
        </h2>

        <p className={ props.textColor + " font-normal text-xl sm:text-2xl sm:mx-24" }>
          { props.description }
        </p>

      </div>
    </div>
  )
}

export default TribalSection;
