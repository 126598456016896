import GetTotalTribal from "../components/TribalStats/getTotalTribal";


function TribalStats() {
  
  return (
    <div className="py-4 px-4 bg-neutral-main">
      <GetTotalTribal />
      
    </div>
  );
}

export default TribalStats;
