import { utils, Contract } from "ethers";
import { useContractFunction, useEthers } from "@usedapp/core";
import { ReserveContainer } from "../containers/ReserveContainer";

import deployments from "../tribal/deployments.json";
import ERC20ABI from "../containers/ABIs/erc20.json";
import { RESERVES } from "../containers/Addresses";

const erc20Interface = new utils.Interface(ERC20ABI);

function ApproveReserve() {
  const { chainId } = useEthers();

  const aDeployment = deployments[chainId] ?? "";
  const tribalTreasuryAddress =
    aDeployment && aDeployment.addresses.tribalTreasury;

  const reserveBalance = ReserveContainer.useContainer();
  const reserveAddress = RESERVES[chainId] ?? "";

  const { state, send } = useContractFunction(
    reserveAddress && new Contract(reserveAddress, erc20Interface),
    "approve",
    { transactionName: "Approve spend of Reserve tokens by the protocol" }
  );

  const approveReserve = () => {
    // Approve the treasury to spend the Reserve tokens of the user
    send(tribalTreasuryAddress, reserveBalance);
  };

  return (
    <ReserveContainer.Provider>
      <button
        type="button"
        class="rounded-full bg-secondary-main h-11 mx-4 my-4 px-4 font-medium text-white shadow-md hover:bg-secondary-light active:bg-secondary-dark focus:outline-none focus:ring focus:ring-secondary-main"
        onClick={() => {
          approveReserve();
        }}
      >
        Approve
      </button>
    </ReserveContainer.Provider>
  );
}

export default ApproveReserve;
