import { useTokenBalance, useEthers } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";

import Balance from "../Balance";
import TribalTokenSpan from "../span/TribalTokenSpan";

import deployments from "../../tribal/deployments.json";

function TribalBalance() {
  const { account, chainId } = useEthers();

  const aDeployment = deployments[chainId] ?? "";
  const tribalERC20Address = aDeployment && aDeployment.addresses.tribalERC20;

  const tribalERC20Balance = useTokenBalance(tribalERC20Address, account);

  const number = tribalERC20Balance
    ? Number(formatEther(tribalERC20Balance)).toFixed(2)
    : undefined;

  return (
    <Balance label="Your Tribal:" balance={number}>
      <TribalTokenSpan />
    </Balance>
  );
}

export default TribalBalance;
