import { useEthers, useCall } from "@usedapp/core";
import { utils, Contract } from "ethers";
import { formatEther } from "@ethersproject/units";

import deployments from "../../tribal/deployments.json";
import TribalDistributorJSON from "../../tribal/contracts/TribalDistributor.sol/TribalDistributor.json";

const distributorInterface = new utils.Interface(TribalDistributorJSON.abi);

function useGetTotalTribal(distributorAddress) {
  const { value, error } =
    useCall(
      distributorAddress && {
        contract: new Contract(distributorAddress, distributorInterface),
        method: "getTotalTribal",
        args: [],
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined; 
  }
  return value?.[0];
}

function GetTotalTribal() {
  const { chainId } = useEthers();

  const aDeployment = deployments[chainId] ?? "";
  const distributorAddress = aDeployment && aDeployment.addresses.tribalDistributor;

  const totalValue = useGetTotalTribal(distributorAddress);

  const totalTribal = totalValue
    ? Number(formatEther(totalValue)).toFixed(2)
    : undefined;

  return <div>{totalTribal ?? "Value not fetched"}</div>;
}

export default GetTotalTribal;
