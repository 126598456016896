import TribalSection from "../TribalSection";

function TribalInfoSection() {
  return (
    <div>
      <TribalSection
        title={ <span>Tribal is a Protocol</span> }
        header={ <span className="font-medium">Incentives, Tokenomics, and Rewards empower both Audience and Creators</span> }
        description={ <span><p><span className="text-primary-main font-semibold">$Tribal<img className="h-6 w-6 mr-1 inline" src="/tribal-token.png" alt="Tribal icon"/></span> is Inflationary to fund projects, and Deflationary to preserve Value.</p>
          <p>$Tribal is emitted and backed by <span className="text-klima font-semibold">$KLIMA</span>, <span className="text-primary-main font-semibold">$Tribal<img className="h-6 w-6 mr-1 inline" src="/tribal-token.png" alt="Tribal icon"/></span> is then burned by purchasing the creations of the <span className="font-semibold">Tribal Creative</span>.</p></span>}
        bgColor="bg-secondary-light"
        textAccent="text-primary-main"
      />
    </div>
  );
}

export default TribalInfoSection;
