import Body from "./components/Body";
import Home from "./components/Home";
import TribalDashboard from "./pages/TribalDashboard";
import TribalStats from "./pages/TribalStats";
import AboutUs from "./components/AboutUs";
import WhatIsTribal from "./pages/WhatIsTribal"

import { ReserveContainer } from './containers/ReserveContainer';

import TribalNavBar from "./components/TribalNavBar";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";


function App() {

  return (
    <div className="App">
      <ReserveContainer.Provider>
        <TribalNavBar />
        <Outlet />

        <Body>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/what-is-tribal" element={<WhatIsTribal />} />
            <Route path="/get-tribal" element={<TribalDashboard />} />
            <Route path="/stats" element={<TribalStats />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </Body>
      </ReserveContainer.Provider>
    </div>
  );
}

export default App;
