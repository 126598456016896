import React from "react";
import "./index.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Mainnet, Localhost, Moonbeam, DAppProvider } from "@usedapp/core";
import { Moonbase } from "./chains/Moonbase"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getDefaultProvider } from "ethers";

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: "https://mainnet.infura.io/v3/9d938725cfc04b2eae1e4e644d7c4f05",
    [Moonbeam.chainId]: "https://moonbeam.blastapi.io/57a40e04-c4ed-4c3f-91a4-ec2f2a0f399f",
    [Moonbase.chainId]: "https://moonbase-alpha.blastapi.io/a6545c31-0e8e-4238-a25f-47a489ddbab4",
    [Localhost.chainId]: 'http://localhost:7525',
  },
  multicallAddresses: {
    // 1337: '0x0000000000000000000000000000000000000000'
    1284: Moonbeam.multicallAddress,
    1287: "0x72B07bb648d5B8b18F95586478b57299D62fB7F8",
    1337: "0x72B07bb648d5B8b18F95586478b57299D62fB7F8",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <DAppProvider config={ config }>
        <App />
      </DAppProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.querySelector("#root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
