import TribalSlash from "./Home/TribalSlash";

function Home() {
  return (
    <div class="px-4 py-4 lg:px-36">
      <TribalSlash />
      <div class="lg:text-center flex flex-col">
        <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-7xl md:text-8xl lg:text-9xl">
          <span class="block">
            Welcome to the <span class="text-secondary-main">Tribal</span>
          </span>{" "}
        </h1>
        <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-6xl md:text-8xl">
          <span class="block text-primary-main">
            Core Creative
          </span>
        </h2>
        <span class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-2xl md:mt-5 md:text-3xl lg:text-4xl lg:my-12 lg:max-w-screen-lg lg:self-center">
          The Tribal Creative is a group and protocol that empowers its core
          members while they create awesome universes and content!
        </span>
        <div class="mt-5 sm:mt-8 sm:flex sm:justify-center">
          <div class="rounded-md shadow">
            <a
              href="/what-is-tribal"
              class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-800 bg-primary-main hover:bg-primary-light md:py-4 md:text-2xl md:px-10 lg:text-4xl"
            >
              Get started
            </a>
          </div>
          <div class="mt-3 sm:mt-0 sm:ml-3">
            <a
              href="/about"
              class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-200 bg-secondary-main hover:bg-secondary-light md:py-4 md:text-2xl lg:text-4xl md:px-10"
            >
              About Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
