import TribalSection from "../TribalSection";

function TribalIsChainedSection(props) {
  return <TribalSection 
    title= { <span>Tribal Is Chained to Carbon</span> } 
    header={ <span>Each <span className="font-semibold">$Tribal<img className="h-6 w-6 mr-1 inline" src="/tribal-token.png" alt="Tribal icon"/></span> is backed by a <span className="text-klima font-semibold">$KLIMA</span> Carbon-chained token! </span> } 
    description={<span>Linked to the Carbon-based reserve token by the <a href="https://www.klimadao.finance/">KLIMA DAO</a>, each <span className="font-semibold">$Tribal</span> is emitted by exchanging $KLIMA. The Tribal Creative Core values the work and creations of the Tribal Creative Core in terms of Earth-based, Tangible Value.</span>}
  bgColor="bg-neutral-main"
  textAccent="text-klima"
    textColor="text-secondary-main"
    />;
}

export default TribalIsChainedSection;
