import { utils, Contract } from "ethers";
import { useEthers, useContractFunction } from "@usedapp/core";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import deployments from "../../tribal/deployments.json";
import TribalDistributorJSON from "../../tribal/contracts/TribalDistributor.sol/TribalDistributor.json";

import ApproveReserve from "../../components/ApproveReserve";

import { ReserveContainer } from "../../containers/ReserveContainer";

import { useApprovedReserve } from "../../hooks/useApprovedReserve";

const distributorInterface = new utils.Interface(TribalDistributorJSON.abi);

function Actions() {
  const { chainId } = useEthers();

  const aDeployment = deployments[chainId] ?? "";
  const distributorAddress =
    aDeployment && aDeployment.addresses.tribalDistributor;

  const { state, send } = useContractFunction(
    distributorAddress &&
      new Contract(distributorAddress, distributorInterface),
    "exchangeReserveForTribal",
    { transactionName: "Exchange" }
  );

  const exchange = () => {
    const reserveInput = sessionStorage.getItem("reserveInput");

    const formattedInput = utils.parseUnits(reserveInput);

    send(formattedInput);
  };

  const reserveBalance = ReserveContainer.useContainer();
  const allowanceBalance = useApprovedReserve();

  const approved =
    reserveBalance && allowanceBalance && reserveBalance.lte(allowanceBalance);
  const hasReserve = reserveBalance > 0;

  return (
    <div className="actions" class="flex justify-end">
      {chainId && chainId !== 1284 && (
        <a href="https://www.klimadao.finance/" target="_blank">
          <button
            type="button"
            class="rounded-full bg-gray-200 h-11 mx-4 my-4 px-4 font-medium text-[#0c3] shadow-md hover:bg-white hover:text-klima focus:outline-none focus:ring focus:ring-[#c3]"
          >
            Get KLIMA
          </button>
        </a>
      )}

      {!approved && <ApproveReserve />}
      {approved && hasReserve && (
        <button
          type="button"
          class="rounded-full bg-secondary-main h-11 mx-4 my-4 px-4 font-medium text-white shadow-md hover:bg-secondary-light active:bg-secondary-dark focus:outline-none focus:ring focus:ring-secondary-main"
          onClick={() => {
            exchange();
          }}
        >
          Exchange
        </button>
      )}
      {approved && !hasReserve && (
        <button
          type="button"
          class="rounded-full disabled bg-secondary-main h-11 mx-4 my-4 px-4 font-medium text-white shadow-md hover:bg-secondary-light active:bg-secondary-dark focus:outline-none focus:ring focus:ring-secondary-main"
          onClick={() => {
            toast("Please acquire some Reserve token (Polygon: KLIMA, Moonbeam: GLMR");
          }}
        >
          Exchange
        </button>
      )}
      <ToastContainer 
        hideProgressBar
        bodyClassName={() => "text-sm text-secondary-main font-semibold block p-3"}
        position="bottom-right"
      />
    </div>
  );
}

export default Actions;
