import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";

const MIN = 1;
const MAX = 10000000;

function TokenInputs() {
  const { chainId } = useEthers();
  const [reserveInput, setReserveInput] = useState(1);

  const handleChange = (event) => {
    const value = event.target.value;
    
    if (value < MIN) {
      value = MIN;
    }

    if (value > MAX) {
      value = MAX;
    }
  
    setReserveInput(value);
  };

  useEffect(() => {
    sessionStorage.setItem("reserveInput", reserveInput);
  });

  return (
    <div className="flex flex-col">
      <div className="flex shrink-0 py-6">
        { chainId && chainId !== 1284 &&
        <img className="h-14 w-14 ml-4 mr-4 rounded-full" src="/klima.png" />
        }
        { chainId && chainId === 1284 &&
        <img className="h-14 w-14 ml-4 mr-4 rounded-full" src="/glimmer.png" />
        }
        { chainId && chainId !== 1284 &&
        <input
          type="number"
          className="w-48 bg-gray-100 mx-4 text-2xl text-right font-medium text-klima rounded appearance-none"
          onChange={handleChange}
          value={reserveInput}
        ></input>
        }
        { chainId && chainId === 1284 &&
        <input
          type="number"
          className="w-48 bg-gray-100 mx-4 text-2xl text-right font-medium text-glmr rounded appearance-none"
          onChange={handleChange}
          value={reserveInput}
        ></input>
        }
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-11 w-11 my-2 fill-secondary-main self-center"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>

      <div className="shrink-0 flex py-6">
        <img className="h-14 w-14 ml-4 mr-4 rounded-full" src="/tribal-token.png" />
        <input
          type="number"
          className="w-48 bg-gray-100 mx-4 text-2xl text-right font-medium text-secondary-main rounded appearance-none"
          onChange={handleChange}
          value={reserveInput}
        ></input>
      </div>
    </div>
  );
}

export default TokenInputs;
