
function Body(props) {
  return (
    <main className="">
      <div id="body" className="flex-col">
        { props.children }
      </div>
    </main>
  ); 
}

export default Body;
