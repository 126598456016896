
function TribalSlash() {
  return (
      <svg className="lg:block right-0 inset-y-0 h:24 w-24 md:h-32 md:w-32 lg:h-48 lg:w-48  translate-x-1/4" fill="#bf360c" viewBox="0 0 100 100" >
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>
  );
}

export default TribalSlash;
