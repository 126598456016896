import { useEthers } from "@usedapp/core";

import KLIMASpan from "../span/KLIMASpan";
import GLMRSpan from "../span/GLMRSpan";

function ExchangeKLIMA() {
  const { chainId } = useEthers();

  return (
    <div className="explain-card" class="mx-8 my-4 rounded shadow-lg bg-white">
      <div class="px-4 py-2">
        <div className="explain-card-title" class="font-bold text-xl">
          2. Exchange {chainId && chainId !== 1284 && <KLIMASpan />}
          {chainId && chainId === 1284 && <GLMRSpan />} for{" "}
          <span className="text-secondary-main">$Tribal</span>.
        </div>
        <span class="text-gray-700 text-base">
          Exchange your {chainId && chainId !== 1284 && "KLIMA"}
          {chainId && chainId === 1284 && "GLMR"} for some $Tribal right here 👌
        </span>
      </div>
    </div>
  );
}

export default ExchangeKLIMA;
