import { utils, Contract } from "ethers";
import { useCall, useEthers } from "@usedapp/core";
import { RESERVES } from "../containers/Addresses";

import deployments from "../tribal/deployments.json";
import erc20ABI from "../containers/ABIs/erc20.json";

const erc20Interface = new utils.Interface(erc20ABI);


export const useApprovedReserve = () => {
  const { account, chainId } = useEthers();

  const aDeployment = deployments[chainId] ?? "";
  const tribalTreasuryAddress =
    aDeployment && aDeployment.addresses.tribalTreasury;


  const { value, error } =
    useCall(
      RESERVES[chainId] && account && {
        contract: new Contract(RESERVES[chainId], erc20Interface),
        method: "allowance",
        args: [account, tribalTreasuryAddress],
      }
    ) ?? {};

  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}
