import TribalSection from "../TribalSection";

function TribalCreatesSection() {
  return (
    <div>
      <TribalSection
        title={<span>Tribal Creates Stories and Games</span>}
        header={
          <span>
            The Tribal Protocol empowers a Core of Creators, Designers and
            Coders, to create a new soil for stories and games
          </span>
        }
        description={
          <span>
            The Tribal Core Creative rewards members of our Core of Creators by
            giving them a mean to fund and distribute their creations. Through
            the exchange of <span className="text-klima font-semibold">$KLIMA</span> tokens for <span className="text-secondary-main font-semibold">$Tribal<img className="h-6 w-6 mr-1 inline" src="/tribal-token.png" alt="Tribal icon"/></span> tokens, our Audience
            ensure that the Core always has the means to achieve its projects.
          </span>
        }
      />
    </div>
  );
}

export default TribalCreatesSection;
