import { useEthers, Moonbeam } from "@usedapp/core";

function SwitchToAuthorizedNetwork() {
  const { chainId } = useEthers();

  async function switchNetwork(chain) {
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x504",
        rpcUrls: ["https://moonbeam.blastapi.io/57a40e04-c4ed-4c3f-91a4-ec2f2a0f399f"],
        chainName: "Moonbeam",
        nativeCurrency: {
          name: "Glimmer",
          symbol: "GLMR",
          decimals: 18
        },
        blockExplorerUrls: [ "https://moonscan.io/" ]
      }]
    });
  }

  return (
    <div>
      <p className="font-medium">Please change to a supported network:</p>
      <button
        class="rounded-full bg-primary-main h-11 mx-4 my-4 px-4 font-medium text-white shadow-md hover:bg-primary-light focus:outline-none focus:ring focus:ring-primary-main"
        onClick={() => switchNetwork(Moonbeam.chainId)}
      >
        Moonbeam
      </button>
    </div>
  );
}

export default SwitchToAuthorizedNetwork;
