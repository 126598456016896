import TribalSlash from "./Home/TribalSlash"

function TribalSlogan(props) {
  return (
    <div>
      <h1 class="text-8xl font-semibold text-secondary-main">About Us</h1>
    </div>
  )
}

function TribalCard(props) {

  return (
    <div class="md:flex md:p-0 rounded-xl shadow p-8 bg-secondary-200 my-8">
      { props.src && <img class="w-64 h-64 md:h-auto rounded-full md:rounded-none" src={ props.src }/> }
      <div class="pt-6 text-center space-y-4 md:text-left md:p-8">
        <figcaption>
          <div class="text-secondary-main font-bold text-2xl">
            { props.name }
          </div>
          <div class="text-primary-dark font-medium text-lg">
            { props.role }
          </div>
        </figcaption>
        <blockquote>
          <p class="text-base font-light">
            { props.description }
          </p>
        </blockquote>
      </div>
    </div>
  )
}


function AboutUs() {
  return (
    <div>
    <div class="py-4 px-4 bg-neutral-main">
      <TribalSlash />
      <TribalSlogan />
    </div>
    <div class="py-4 px-4 bg-primary-200">

      <div class="">
        <TribalCard name="Alpha Diop" role="Solidity Engineer" description={ "Crypto-Advocate and Blockchain-Enthusiast, Enlightenment through PvP and Entertainment!" } src="/diop-alpha.jpg" />
        <TribalCard name="Dom Fury" role="Artist, Writer" description={ "Animated by Beats and by Roots, a Universe to Share and a Story to tell!" } src="/dom-fury.jpg" />
      </div>
    </div>
    </div>
  );
}

export default AboutUs;
