import { useEthers, Moonbeam } from "@usedapp/core";

const navigation = [
  { name: "Welcome", href: "/", current: false },
  { name: "What is Tribal?", href: "/what-is-tribal", current: false },
  { name: "Get $Tribal", href: "/get-tribal", current: false },
  { name: "About", href: "/about", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavLink(props) {
  return (
    <button className="px-3 py-2 bg-secondary-light hover:bg-secondary-main hover:text-white shadow rounded-md text-sm font-medium"
      >{ props.children }</button>
  )
}

function AccountSpan() {
  const { account } = useEthers()
  
  return (
    <div className="w-3/5 overflow-hidden truncate ... pr-3">
      { account && <span className="text-md text-right font-medium text-secondary-dark ">{ account }</span> }
    </div>
  )
}

function ConnectButton() {
  const { activateBrowserWallet, deactivate, account } = useEthers()

  return (
    <div className="pb-2 md:py-2 flex">
      <div className=" flex px-3 items-center overflow-hidden justify-end">
        <AccountSpan />
        { !account && <button onClick={ activateBrowserWallet }>Connect</button> }
        {account && <button onClick={ deactivate }>Disconnect</button>}
      </div>
    </div>
  )
}

export default function TribalNavBar() {

  return (
    <nav className="bg-primary-main">
      <div className="">
        <div className="md:flex">
          <div className="py-2 flex items-center">
            { navigation.map( (item, index) => (
              <div className="px-3 py-2" key={index} >
                <a
                  key={ item.name }
                  href={ item.href }
                  className={ classNames(
                    item.current
                      ? "text-secondary-main"
                      : "text-secondary-light hover:bg-primary-light hover:text-secondary-main",
                      "rounded-md text-sm font-medium"
                  )}
                >
                  { item.name }
                </a>
              </div>
            ))}
          </div>

          <ConnectButton />
          
        </div>
      </div>
    </nav>
  );
}
