import { createContainer } from "unstated-next";
import { useTokenBalance, useEthers } from '@usedapp/core';
import { RESERVES } from './Addresses';

function useReserveBalance () {
  const { account, chainId } = useEthers();

  const reserveAddress = RESERVES[chainId] ?? "";

  const reserveBalance = useTokenBalance(reserveAddress, account);

  return reserveBalance;
}

export const ReserveContainer = createContainer(useReserveBalance);
