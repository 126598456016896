import { Chain } from '@usedapp/core'

export const Moonbase: Chain = {
  chainId: 1287,
  chainName: 'Moonbase Alpha',
  isTestChain: true,
  isLocalChain: false,
  getExplorerAddressLink: (address: string) => `https://moonbase.moonscan.io/address/${address}`,
  getExplorerTransactionLink: (transactionHash: string) => `https://moonbase.moonscan.io/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://moonbeam-alpha.api.onfinality.io/public',
  blockExplorerUrl: 'https://moonbase.moonscan.io',
  nativeCurrency: {
    name: 'DevCoin',
    symbol: 'DEV',
    decimals: 18,
  }
}
