import AcquireKLIMA from "../components/TribalDashboard/AcquireKLIMA";
import ExchangeKLIMA from "../components/TribalDashboard/ExchangeKLIMA";
import SwapKLIMAforStory from "../components/TribalDashboard/SwapKLIMAforStory";

import TribalSlash from "../components/Home/TribalSlash";

function TribalDashboard() {
  return (
    <div className="py-4 px-4 bg-neutral-main">
      <TribalSlash />
      <AcquireKLIMA />
      <ExchangeKLIMA />

      <SwapKLIMAforStory />
    </div>
  );
}

export default TribalDashboard;
