
function Balance(props) {

  const label = props.label
  const balance = props.balance

  return (
    <div className="flex flex-col py-1">
      <span className="text-lg font-medium">{ label }</span>
      { balance && (
        <div className="space-x-2">
          <span className="text-2xl font-normal">{ balance }</span>
          { props.children }
        </div>
      )}
    </div>
  );
}

export default Balance;
